/**
 * Most functions taken from jQuery.scrollable (core)
 */
(function ( $ ) {
    "use strict";

    /** @type {string}  caches the name of the element to use for window scrolling. Values: "body" or "html" */
    var _windowScrollable;

    /**
     * Detects which element to use for scrolling a window (body or documentElement). Returns the element, in a jQuery
     * wrapper.
     *
     * The detection is sandboxed in an iframe created for the purpose.
     *
     * @param {Window} [currentWindow=window]  needed to detect quirks mode in a particular window, and to return the
     *                                         scrollable element in the right window context
     * @returns {jQuery}
     */
    function getWindowScrollable ( currentWindow ) {
        var iframe, element, tagName,
            currentDocument = ( currentWindow || window ).document;

        // In quirks mode, we have to scroll the body, regardless of the normal browser behaviour
        if ( currentDocument.compatMode === "BackCompat" ) return $( currentDocument.body );

        // Use the document.scrollingElement API if available.
        //
        // This API is experimental and currently supported by Chrome 44+, Opera 33+, Safari 9+ and Edge.
        // See https://developer.mozilla.org/en-US/docs/Web/API/document/scrollingElement
        if ( ! _windowScrollable ) {
            // Being very defensive here because the API is experimental and potentially buggy, unstable, or slow.
            element = document.scrollingElement;
            tagName = element && element.tagName;

            if ( tagName && tagName.toLowerCase ) {
                tagName = tagName.toLowerCase();
                if ( tagName === "html" || tagName === "body" ) _windowScrollable = tagName;
            }
        }

        if ( ! _windowScrollable ) {
            iframe = createScrollableTestIframe();

            iframe.contentWindow.scrollTo( 1, 0 );
            _windowScrollable = iframe.contentDocument.documentElement.scrollLeft === 1 ? "html" : "body";

            document.body.removeChild( iframe );
        }

        return _windowScrollable === "html" ? $( currentDocument.documentElement ) : $( currentDocument.body );
    }

    /**
     * Creates an iframe document with an HTML5 doctype and UTF-8 encoding and positions it off screen. Window size
     * is 500px x 500px. Its body is 550px wide, in preparation for a horizontal scroll test. Returns the iframe element.
     *
     * The iframe content has to overflow horizontally, not vertically, because of an iOS quirk. Iframes expand
     * vertically in iOS until the content fits inside (and as a result, we are unable to scroll vertically). But
     * iframes don't expand horizontally, so scrolling on that axis works in iOS.
     *
     * @returns {HTMLIFrameElement}
     */
    function createScrollableTestIframe () {
        var iframe = document.createElement( "iframe" ),
            body = document.body;

        iframe.style.cssText = "position: absolute; top: -600px; left: -600px; width: 500px; height: 500px; margin: 0px; padding: 0px; border: none; display: block;";
        iframe.frameborder = "0";

        body.appendChild( iframe );
        iframe.src = 'about:blank';

        iframe.contentDocument.write( '<!DOCTYPE html><html><head><meta charset="UTF-8"><title></title><style type="text/css">body { width: 550px; height: 1px; }</style></head><body></body></html>' );

        return iframe;
    }

    // Let's prime getWindowScrollable() immediately after the DOM is ready. It is best to do it up front because the
    // test touches the DOM, so let's get it over with before people set up handlers for mutation events and such.
    $( function () {
        if ( _windowScrollable === undefined ) getWindowScrollable();
    } );

    // Export jQuery additions
    $.windowScrollTop = function () {
        var scrollable = getWindowScrollable();
        return scrollable.scrollTop.apply( scrollable, arguments );
    };

    $.windowScrollLeft = function () {
        var scrollable = getWindowScrollable();
        return scrollable.scrollLeft.apply( scrollable, arguments );
    };

})( jQuery );